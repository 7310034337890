import React, {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import IframeLayout from './layouts/IframeLayout';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/prescription_auth/:professional_medic_id/:prescription_public_id',
        component: lazy(() => import('src/views/auth/AuthPrescription'))
    },
    {
        exact: true,
        path: '/login/:organization_id/:url?',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/login/professional/:professional_medic_id/:url?',
        component: lazy(() => import('src/views/auth/LoginView/ProfessionalIdLogin'))
    },
    {
        exact: true,
        path: '/register/:organization_id',
        component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
        exact: true,
        path: '/passwordRecovery/:organization_id',
        component: lazy(() => import('src/views/auth/PasswordRecoveryView'))
    },
    {
        exact: true,
        path: '/reset/password/:token/:redirect_url?',
        component: lazy(() => import('src/views/auth/ResetPasswordView'))
    },
    {
        exact: true,
        path: '/landing/:channelView',
        component: lazy(() => import('src/views/channel/LandingPageView/'))
    },
    {
        exact: true,
        path: '/channelView/:channelView',
        component: lazy(() => import('src/views/channel/ChannelDetailView'))
    },
    {
        exact: true,
        path: '/channelView/:channelView/:video_id',
        component: lazy(() => import('src/views/channel/ChannelDetailView'))
    },
    {
        exact: true,
        path: '/accept/channel/invitation/:professionalMedicId/:channelId/:code',
        component: lazy(() => import('src/views/auth/AcceptInvitationView'))
    },
    {
        exact: true,
        path: '/3D/:model_3d_id',
        component: lazy(() => import('src/views/models/ModelsDetailsView'))
    },
    {
        exact: true,
        path: '/prescription/:prescription_public_id',
        component: lazy(() => import('src/views/questionnaires/AnswerQuestionnairePublic'))
    },
    {
        exact: true,
        path: '/questionnaires/pdf/:questionnaire_public_id',
        component: lazy(() => import('src/views/questionnaires/pdf'))
    },
    {
        path: '/iframe/:token/:optional_params?',
        component: lazy(() => import('src/views/iframe/index'))
    },
    {
        exact: true,
        path: '/signin/sescam/:operation?',
        component: lazy(() => import('src/views/sescamLogin/index'))
    },
    {
        exact: true,
        path: '/logout/sescam/',
        component: lazy(() => import('src/views/sescamLogin/LogOut'))
    },
    {

        path: '/video/:entry_id/:hotspot_list?',
        component: lazy(() => import('src/views/VideoHotspot/index'))
    },
    {
        path: '/bellvitge',
        component: lazy(() => import('src/views/mediapipe/index'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app/homepage',
                component: lazy(() => import('src/views/homepage/GlobalChannelView'))
            },
            {
                exact: true,
                path: '/app/prescriptions/:optional_params?',
                component: lazy(() => import('src/views/prescription/PrescriptionListView'))
            },
            {
                exact: true,
                path: '/app/patientPrescription/:prescription_id',
                component: lazy(() => import('src/views/patient/PatientDetailsView/Prescriptions/index'))
            },
            {
                exact: true,
                path: '/app/3D',
                component: lazy(() => import('src/views/models/ModelsListView'))
            },
            {
                exact: true,
                path: '/app/patients/:optional_params?',
                component: lazy(() => import('src/views/patient/PatientListView'))
            },
            {
                exact: true,
                path: '/app/patient/:patient_id/:optional_params?',
                component: lazy(() => import('src/views/patient/PatientDetailsView'))
            },
            {
                exact: true,
                path: '/app/channels',
                component: lazy(() => import('src/views/channel/ChannelListView'))
            },
            {
                exact: true,
                path: '/app/channel/:channelView',
                component: lazy(() => import('src/views/channel/ChannelDetailView'))
            },
            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView'))
            },
            {
                exact: true,
                path: '/app/myContent',
                component: lazy(() => import('src/views/myContent/MyContentView'))
            },
            {
                exact: true,
                path: '/app/patientEconsent/:eConsent_id',
                component: lazy(() => import('src/views/patient/PatientDetailsView/PatientEConsentView'))
            },
            {
                exact: true,
                path: '/app/econsents',
                component: lazy(() => import('src/views/econsents/E-consentsGallery'))
            },
            {
                exact: true,
                path: '/app/chats',
                component: lazy(() => import('src/views/chat/ChatView'))
            },
            {
                exact: true,
                path: '/app/trajectories',
                component: lazy(() => import('src/views/trajectories/TrajectoriesGallery'))
            },
            {
                exact: true,
                path: '/app/patientTrajectory/:trajectory_id',
                component: lazy(() => import('src/views/patient/PatientDetailsView/PatientTrajectoryView'))
            },
            {
                exact: true,
                path: '/app/patientNavigator/:navigator_public_id',
                component: lazy(() => import('src/views/patient/PatientDetailsView/PatientNavigatorView'))
            },
            {
                exact: true,
                path: '/app/questionnaires',
                component: lazy(() => import('src/views/questionnaires/gallery/index'))
            },
            {
                exact: true,
                path: '/app/patientQuestionnaire/:questionnaire_id',
                component: lazy(() => import('src/views/patient/PatientDetailsView/Questionnaires/index'))
            },
            {
                exact: true,
                path: '/app/healthNavigator',
                component: lazy(() => import('src/views/healthNavigator/healthNavigatorGallery'))
            },
            {
                exact: true,
                path: '/app/healthNavigator/:navigator_template_id',
                component: lazy(() => import('src/views/healthNavigator/healthNavigatorPreview'))
            },
            {
                exact: true,
                path: '/app/healthNavigator/edit/:navigator_template_id',
                component: lazy(() => import('src/views/healthNavigator/healthNavigatorEdit'))
            },
            {
                exact: true,
                path: '/app/vrprograms',
                component: lazy(() => import('src/views/vrprograms/VrProgramsGallery/index'))
            },
            {
                exact: true,
                path: '/app/patientVrprogram/:vr_program_id',
                component: lazy(() => import('src/views/patient/PatientDetailsView/PatientVrProgramView'))
            },
            {
                exact: true,
                path: '/app/prescription/:prescription_public_id',
                component: lazy(() => import('src/views/questionnaires/AnswerQuestionnaire'))
            }
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/'
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

export default routes;
