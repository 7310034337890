import { usePDF } from '@react-pdf/renderer';
import axios from 'axios';
import parse from "html-react-parser";
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import * as sanitizeHtml from 'sanitize-html';
import { eConsent } from 'src/types/eConsent';
import organizationTagsCa from '../../compiled-organization-tags/organization-tags-cat.json';
import organizationTagsEn from '../../compiled-organization-tags/organization-tags-en.json';
import organizationTagsEs from '../../compiled-organization-tags/organization-tags-es.json';
import EConsentPdf from '../patient/PatientDetailsView/PatientEConsentView/EconsentPDF';

export const getWSConfig = () => {
    let token = localStorage.getItem('accessToken');
    let config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return config;
}

export const getProfessionalPortalUrl = (): string => {
    if (process.env.REACT_APP_BUILD_ENV === 'local') {
        return 'http://localhost:3001/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'dev') {
        return 'https://dev.pro.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'uat') {
        return 'https://uat.pro.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'sescam-uat') {
        return 'https://canalsaludadminpre.sanidad.castillalamancha.es/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'sescam-prod') {
        return 'https://sescam-prod.pro.digimevo.com/';
    }
    else {
        return 'https://www.pro.digimevo.com/';
    }
}

export const getPatientPortalUrl = (): string => {
    if (process.env.REACT_APP_BUILD_ENV === 'local') {
        return 'http://localhost:3002/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'dev') {
        return 'https://dev.app.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'uat') {
        return 'https://uat.app.digimevo.com/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'sescam-uat') {
        return 'https://canalsaludpre.sanidad.castillalamancha.es/';
    }
    else if (process.env.REACT_APP_BUILD_ENV === 'sescam-prod') {
        return 'https://sescam-prod.app.digimevo.com/';
    }
    else {
        return 'https://www.app.digimevo.com/';
    }
}

export const getSubDomainChannelView = () => {
    let prefix = window.location.host.split('.')[0];
    let subdomain = null;
    const subdomainmap = {
        fisiotv: {
            channelview: 30,
        },
        pestanaga: {
            channelview: 27,
        }
    };
    if ((prefix != null || prefix != undefined) && (subdomainmap[prefix])) {

        subdomain = subdomainmap[prefix].channelview;

    } else {
        subdomain = null;
    }
    return subdomain;
}

export const isIframe = () => {
    return window.location.pathname.startsWith("/iframe/")
}

export const isAnIntegerValue = (text: string) => {
    return /\d/.test(text);
}

/**
 * 
 * @description for when we use a dangerouslySetInnerHTML prop
 * @param htmlText 
 * @returns a parsed html compiled with only safe tags
 */
export const cleanAndParseHtmlText = (htmlText) => {
    const cleanHtml = sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'li', 'ol', 'ul', 'u', 'h1', 'h2', 'h3', 'h4', 'h5'],
        allowedAttributes: {
            a: ['href', 'target']
        }
    });
    return parse(cleanHtml);
}

export const cleanHtmlText = (htmlText) => {
    return sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'li', 'ol', 'ul', 'u', 'h1', 'h2', 'h3', 'h4', 'h5'],
        allowedAttributes: {
            a: ['href', 'target']
        }
    });
}

export const getAndStoreKalturaVideoPlayerId = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = { "organization_id": 0 }

    if (user && user.organization_id) {
        params = { "organization_id": user.organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/video/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_professional_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const getAndStoreKalturaVideoPlayerIdByChannelId = async (channel_id: number) => {
    let params = { "channel_id": channel_id }
    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/channel/1.0/get/kaltura/video/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_professional_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const getAndStoreKalturaPlaylistPlayerId = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = { "organization_id": 0 }

    if (user && user.organization_id) {
        params = { "organization_id": user.organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/playlist/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_playlist_player_id_professional_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const deleteKalturaVideoPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_professional_portal');
}

export const deleteKalturaPlaylistPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_professional_portal');
}

export const getOrganizationTag = (propertyPath: string): string => {
    const intl = useIntl();
    const user = JSON.parse(localStorage.getItem('user'))
    const organizationTags = user.language === 'en' ? organizationTagsEn : (user.language === 'es' ? organizationTagsEs : organizationTagsCa);

    if (user.organization_id !== undefined && user.organization_id !== null) {
        const organizationId = user.organization_id
        const organizationIdAsString = String(organizationId);
        const currentObject = organizationTags[organizationIdAsString]
        const properties = propertyPath.split('.');
        let value = currentObject;
        for (const property of properties) {
            if (value && typeof value === 'object' && property in value) {
                value = value[property];
            } else {
                return intl.formatMessage({ id: propertyPath })
            }
        }

        return (typeof value === 'object' || value === undefined) ? intl.formatMessage({ id: propertyPath }) : String(value);
    } else {
        return intl.formatMessage({ id: propertyPath })
    }

};

export const useDownloadEconsentPdf = (eConsent: eConsent) => {
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const [isPdfLoading, setPdfLoading] = useState(false);
    const config = getWSConfig();
    const [pdf, setPDF] = usePDF({
        document: <EConsentPdf eConsent={eConsent} skip_content_pdf={eConsent.skippedContent} intl={intl} />,
    });

    const downloadEconsentPdf = () => {
        if (eConsent.completion_date && !eConsent.signed_manually) {
            downloadSignedDocument();
        } else {
            window.open(pdf.url);
        }
    };

    const downloadSignedDocument = () => {
        let params = { eConsent_public_id: eConsent.eConsent_public_id, type: 'signed' };
        let url = '/eConsent/1.0/get/eConsent/PDF';
        setPdfLoading(true);
        axios.post(process.env.REACT_APP_SERVER_URL + url, params, config)
            .then(function (response) {
                if (response && response.data) {
                    window.location.href = response.data;
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.error('Error downloading document', error.message);
                }
                enqueueSnackbar(
                    intl.formatMessage({ id: 'utils.Utils.problem_downloading_document' }),
                    { variant: 'error' }
                );
            }).finally(() => {
                setPdfLoading(false);
            });
    };

    return { downloadEconsentPdf, isPdfLoading };
};
/**
 * Formats a given date into a localized string based on the user's regional settings.
 * @param {Date} date - The Date object to format.
 * @param {string} alternateString - (Optional) Alternate string to display if date is undefined. Defaults to '-'.
 * @returns {string} - Formatted date string in localized format, or the alternate string if date is undefined.
 */
export const formatDate = (date: Date, alternateString = '-') => {
    const intl = useIntl();

    if (!date) return alternateString;

    return new Date(date).toLocaleDateString(intl.locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }).toLowerCase();
};
