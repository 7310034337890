import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import type { FC } from 'react';
import React from 'react';
import { eConsent } from 'src/types/eConsent';
import { cleanAndParseHtmlText } from 'src/views/utils/Utils';

interface EconsentPDFProps {
    skip_content_pdf: boolean;
    eConsent: eConsent;
    intl: any;
}

const EConsentPdf: FC<EconsentPDFProps> = ({ skip_content_pdf, eConsent, intl }) => {

    const styles = StyleSheet.create({
        body: {
            paddingTop: 24,
            paddingHorizontal: 24,
            paddingBottom: 136,
        },
        section: {
            justifyContent: "space-between",
            flexDirection: "row"
        },
        logo: {
            height: 20,
            width: 100,
        },
        date: {
            fontSize: 14,
        },
        dateView: {
            paddingTop: 24,
        },
        title: {
            paddingTop: 24,
            fontSize: 16,
            textAlign: 'center',
            fontWeight: 600,
        },
        details: {
            paddingTop: 32,
        },
        medic: {
            flexDirection: "row",
            flexWrap: "wrap",
            paddingTop: 8,
        },
        terminos: {
            paddingTop: 24,
            flexDirection: "row"
        },
        dateAccepted: {
            paddingTop: 8,
            flexDirection: "row",
        },
        dateHour: {
            paddingTop: 8,
            flexDirection: "row",
            paddingBottom: 32,
        },
        patientDNI: {
            paddingTop: 8,
            flexDirection: "row",
            flexWrap: "wrap",
        },
        patientEmail: {
            paddingTop: 8,
            flexDirection: "row",
            flexWrap: "wrap",
        },
        boxTitleStep: {
            flexDirection: "row",
            flexWrap: "wrap",
        },
        stepDateComplete: {
            flexDirection: "row",
        },
        boxStepDateComplete: {
            paddingTop: 8,
            justifyContent: "space-between",
            flexDirection: "row",
        },
        accepted: {
            paddingTop: 8,
            paddingBottom: 32,
        },
        explanation: {
            paddingTop: 8,
        },
        patientComments: {
            paddingTop: 32,
            flexDirection: "row",
        },
        professionalComments: {
            paddingTop: 8,
            flexDirection: "row",
            paddingBottom: 32,
        },
    });

    const SkipContentDoc = (
        <Document>
            <Page wrap={true} size="A4" style={styles.body}>
                <View style={styles.section}>
                    <Image
                        style={styles.logo}
                        src="/static/logoblack.png"
                    />
                </View>

                <Text style={styles.title}>{eConsent.title}</Text>

                <View>
                    <Text style={{ fontSize: 14, paddingTop: 24, fontWeight: "bold" }}>{eConsent.sent_to}</Text>

                    <View style={styles.patientDNI}>
                        <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.dni_passport" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.dni}</Text>
                        <Text style={{ fontSize: 14, paddingLeft: 16, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.contact_phone" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.patient_mobile_1}</Text>
                    </View>

                    <View style={styles.patientEmail}>
                        <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.email" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.patient_email}</Text>
                        <Text style={{ fontSize: 14, paddingLeft: 16, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.address" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.patient_direction}</Text>
                    </View>
                </View>

                <View style={styles.details}>
                    <Text style={{ fontSize: 16, textDecoration: "underline" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.eConsent_details" })}</Text>
                </View>

                <View style={styles.medic}>
                    <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.sent_by" })}</Text>
                    <Text style={{ fontSize: 14 }}> {eConsent.sent_by}</Text>
                </View>

                <View style={styles.terminos}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.terms_conditions" })}</Text>
                    <Text style={{ fontSize: 14, color: "green" }}> {eConsent.terms_and_conditions_date !== null ? intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.terms_accepted" }) : ""}</Text>
                </View>

                <View style={styles.dateAccepted}>
                    <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.acceptance_date" })} </Text>
                    <Text style={{ fontSize: 14 }}>{new Date(eConsent.terms_and_conditions_date).toLocaleDateString('es-ES',
                        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) || '-'}</Text>
                </View>

                <View style={styles.dateHour}>
                    <Text style={{ fontSize: 14, color: "grey" }}>{"Hora:"} </Text>
                    <Text style={{ fontSize: 14 }}>{new Date(eConsent.terms_and_conditions_date).toLocaleTimeString('es-ES',
                        { hour: 'numeric', minute: 'numeric', second: 'numeric' }) || '-'}</Text>
                </View>

                <Text style={{ fontSize: 14, paddingBottom: 16 }}>
                    {intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.right_not_receive_info" })}
                </Text>

                <View>
                    <Text style={{ fontSize: 14, paddingBottom: 16 }}>
                        {intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.advised_complications" })}
                    </Text>
                </View>

                <View style={styles.dateView}>
                    <Text style={styles.date}>
                        {intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.SkipContentPDF.current_date" })}, {new Date().toLocaleDateString('es-ES',
                            { year: 'numeric', month: 'long', day: 'numeric' })
                        }
                    </Text>
                </View>
                <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 24 }}>
                        <View style={{ borderBottom: '1px solid black', width: '25%' }} />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 4 }}>
                        <Text style={{ fontSize: 14 }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.patient_signature" })}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );

    const FinishedContentDoc = (
        <Document>
            <Page wrap={true} size="A4" style={styles.body}>
                <View style={styles.section}>
                    <Image
                        style={styles.logo}
                        src="/static/logoblack.png"
                    />
                </View>

                <Text style={styles.title}>{eConsent.title}</Text>

                <View>
                    <Text style={{ fontSize: 14, paddingTop: 24, fontWeight: "bold" }}>{eConsent.sent_to}</Text>

                    <View style={styles.patientDNI}>
                        <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.dni_passport" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.dni}</Text>
                        <Text style={{ fontSize: 14, paddingLeft: 16, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.contact_phone" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.patient_mobile_1}</Text>
                    </View>

                    <View style={styles.patientEmail}>
                        <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.email" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.patient_email}</Text>
                        <Text style={{ fontSize: 14, paddingLeft: 16, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.address" })}</Text>
                        <Text style={{ fontSize: 14 }}>{eConsent.patient_direction}</Text>
                    </View>
                </View>

                <View style={styles.details}>
                    <Text style={{ fontSize: 16, textDecoration: "underline" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.eConsent_details" })}</Text>
                </View>

                <View style={styles.medic}>
                    <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.sent_by" })}</Text>
                    <Text style={{ fontSize: 14 }}> {eConsent.sent_by}</Text>
                </View>

                <View style={styles.terminos}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.terms_conditions" })}</Text>
                    <Text style={{ fontSize: 14, color: "green" }}> {eConsent.terms_and_conditions_date !== null ? intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.terms_accepted" }) : ""}</Text>
                </View>

                <View style={styles.dateAccepted}>
                    <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.acceptance_date" })} </Text>
                    <Text style={{ fontSize: 14 }}>{new Date(eConsent.terms_and_conditions_date).toLocaleDateString('es-ES',
                        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) || '-'}</Text>
                </View>

                <View style={styles.dateHour}>
                    <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.time" })} </Text>
                    <Text style={{ fontSize: 14 }}>{new Date(eConsent.terms_and_conditions_date).toLocaleTimeString('es-ES',
                        { hour: 'numeric', minute: 'numeric', second: 'numeric' }) || '-'}</Text>
                </View>

                <Text style={{ fontSize: 14, paddingBottom: 16 }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.procedure_stages" })}</Text>

                {eConsent.eConsentStepList.map((step, index) => (
                    <View key={index}>
                        <View style={styles.boxTitleStep}>
                            <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.stage" })}{" " + step.sequence + " " + "- "}</Text>
                            <Text style={{ fontSize: 14 }}>{step.title + " "}</Text>
                        </View>
                        <View style={styles.boxStepDateComplete}>
                            <View style={styles.stepDateComplete}>
                                <Text style={{ fontSize: 14, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.stage_completed_on" })}</Text>
                                <Text style={{ fontSize: 14 }}>{new Date(step.completion_date).toLocaleDateString('es-ES',
                                    { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) || '-'}</Text>
                                {!step.is_risk &&
                                    <>
                                        <Text style={{ fontSize: 14, paddingLeft: 16, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.viewing" })}</Text>
                                        <Text style={{ fontSize: 14 }}>{step.video_viewed_percentage + "%"}</Text>
                                    </>
                                }
                                {step.is_risk &&
                                    <>
                                        <Text style={{ fontSize: 14, paddingLeft: 16, color: "grey" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.viewing" })}</Text>
                                        <Text style={{ fontSize: 14 }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.yes" })}</Text>
                                    </>
                                }
                            </View>
                        </View>
                        {step.explanation && step.explanation !== "" &&
                            <View style={styles.explanation}>
                                <Text style={{ fontSize: 14 }}>{step.explanation}</Text>
                            </View>
                        }
                        {step.is_risk &&
                            <View style={styles.explanation}>
                                <Text style={{ fontSize: 14 }}>{cleanAndParseHtmlText(step.description)}</Text>
                            </View>
                        }
                        <View style={styles.accepted}>
                            <Text style={{ fontSize: 14, color: "green" }}>{step.video_understood ? intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.accepted" }) : ""} </Text>
                        </View>
                    </View>
                ))}

                <View>
                    <Text style={{ fontSize: 14 }}>
                        {intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.informed_complications" })}
                    </Text>
                </View>

                <View style={styles.patientComments}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.patient_comments" })}</Text>
                    <Text style={{ fontSize: 14, color: "grey" }}> {eConsent.patient_questions === null ? intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.no_comments" }) : (eConsent.patient_questions).replace(/<[^>]+>/g, '')}
                    </Text>
                </View>

                <View style={styles.professionalComments}>
                    <Text style={{ fontSize: 14, fontWeight: "bold" }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.professional_response" })}</Text>
                    <Text style={{ fontSize: 14, color: "grey" }}> {eConsent.professional_answers_body === null ? intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.no_comments" }) : (eConsent.professional_answers_body).replace(/<[^>]+>/g, '')}</Text>
                </View>

                <Text style={styles.date}>
                    {intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.current_date" })}, {new Date().toLocaleDateString('es-ES',
                        { year: 'numeric', month: 'long', day: 'numeric' })
                    },
                </Text>
                <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 24 }}>
                        <View style={{ borderBottom: '1px solid black', width: '25%' }} />
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 4 }}>
                        <Text style={{ fontSize: 14 }}>{intl.formatMessage({ id: "patient.PatientDetailsView.PatientEConsentView.EconsentPDF.patient_signature" })}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );

    if (skip_content_pdf) {
        return SkipContentDoc;
    } else {
        return FinishedContentDoc;
    }
};

export default EConsentPdf;